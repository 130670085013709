import React, { useState } from 'react';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Modal, Form, Button } from 'react-bootstrap';

// Quill Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { callAxios, callAxiosFile } from '../../utils/utils';
import { environment } from '../../environment';
import { toast } from 'react-toastify';

const animatedComponents = makeAnimated();


export const AddNewIssueModal = ({ show, close, user, setIssues, userRole, setShowAddIssueModal, startDate, endDate, setStatusD, setCurrentItem, setIsloading, getIssues }) => {
    // React-slick npm style customisation
    const [subject, setSubject] = useState("");
    const [priority, setPriority] = useState("");
    const [description, setDescription] = useState("");
    const [files, setFiles] = useState([]);

  const [preview, setPreview] = useState([])
    const checkValidation = (key, val) => {
        if (key === "subject" && val !== "") {
          document.getElementById("subject").style.border = "none";
        }
        if (key === "priority" && val !== "") {
          document.getElementById("priority").style.border = "none";
        }
        if (key === "description" && val !== "") {
          document.getElementById("issue-description").style.border = "none";
        }
    
        if (key === "changeStatus" && val !== "") {
          document.getElementById("changeStatus").style.border = "none";
        }
      };
    
      if (description) {
        document.getElementById("issue-description").style.border = "none";
      }
      const handleFormSubmit = async (e) => {
        e.preventDefault();
       
        if (subject === "") {
          document.getElementById("subject").style.border = "1px solid red";
          return false;
        }  else if (description === "") {
          document.getElementById("issue-description").style.border =
            "1px solid red";
          return false;
        } else {
          setIsloading(true)
          
          const formData = {
            uid: user?.uid,
            subject: subject,
            priority: priority,
            status: "open",
            description: description,
            name: user?.displayName,
            email: user?.email,
            userType: userRole?.userType,
           files:preview,
          attachmentType:"issue"
          };
          // console.log(formData)
    
          const response = await callAxios(
            environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/save-issue",
            formData,
            "post"
          );
    
          if (response?.data?.status === 200) {
            resetForm();
            setPreview([])
            setShowAddIssueModal(false)
            toast.success("Incentive Query Submitted!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            
            toast.error("Network Issue!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      };
    
      const resetForm = async () => {
        //console.log("In reset form");
        setSubject("");
        setDescription("");
        // const element = document.getElementsByClassName("ql-editor");
        // element[0].innerHTML = "";
        
        getIssues("pending")
        // document.getElementById("issueForm").reset();
    
        // document.getElementById("closeIssueForm").click();
        
      };



    // Define the quill toolbar modules
    const toolbarModules = {
        toolbar: {
            container: '#toolbar', // Reference the ID of the custom toolbar
            handlers: {
                // Add custom handlers if necessary
            }
        },
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }],
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'color': [] }, { 'background': [] }],
            ['link', 'video', 'formula'],
            ['blockquote', 'code-block'], // Quote
            ['clean'] // Remove formatting button
        ],
    };

    // Define the quill formats that are allowed
    const formats = [
        'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
    ];

    const uploadFiles__ = async (event) => {
      setIsloading(true)
      const selectedFiles = event.target.files; // Get the selected files
      // console.log(selectedFiles);
    
      // Check if there are any selected files
      if (selectedFiles.length > 0) {
        const formData = new FormData();
    
        // Append each selected file to the formData object
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append('files', selectedFiles[i]); // 'files' should match the key in the backend
        }
    
        try {
          // Send a POST request to the Express API with the files
          const response = await callAxiosFile(environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/upload-to-s3", formData);
  
          setPreview(response.data)
          setIsloading(false)
          // console.log('Files uploaded successfully:', response.data);
    
          // Clear the file input
          event.target.value = null; // Reset the file input
        } catch (error) {
          console.error('Error uploading files:', error);
        }
      }
    };
// console.log(preview)
const handleDelete = async(itemUrl) =>{
  // console.log(itemId, itemUrl)
  const updatedAttachments = preview.filter(item => item.upload !== itemUrl);
  setPreview(updatedAttachments)
  callAxios(environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/delete-attachment",{url:itemUrl},"post").then((res)=>{
    
  })
}
    return (
        <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} dialogClassName="addNewIssueModal mw-auto" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="px-lg-4 px-xl-5 py-4">
                <div className="custom-wrapper my-2 pb-1">
                    <div className="fs-3 fw-bold text-dark text-center lh-sm mb-4">Submit Incentive Query</div>
                    <Form className="user-form-details d-flex flex-column h-100" onSubmit={handleFormSubmit}>
                        <Form.Group className="mb-3" controlId="addSubject">
                            <Form.Control type="text" id="subject" className="custom-input fw-medium border shadow-none py-2" placeholder="Enter a brief title for your query" autoComplete="off" required  onChange={(e) => {
                      setSubject(e.target.value);
                      checkValidation("subject", e.target.value);
                      
                    }} 
                    
                    />
                        </Form.Group>
                      

                        <div className="editor-section mb-3">
                            {/* React Quill Editor */}
                            <ReactQuill id="issue-description" className="custom-quill-editor border rounded overflow-hidden" theme="snow" placeholder="Provide detailed information about your query or concern. Be specific to your query so that the Helpdesk admin can understand and respond accurately."
                                modules={toolbarModules}
                                formats={formats}
                                onChange={setDescription}
                                
                            />
                        </div>
                        <div className="files-wrapper d-flex flex-wrap gap-2 mb-2">
                                {preview && preview.length >0 && preview.map((item,index)=>(
                                <div className="file-btn fs-14 text-dark text-opacity-75 d-flex gap-1 border border-secondary rounded-3 position-relative px-2 py-1 py-xl-2">
                                   <span className="deleteAttachment position-absolute z-3 bg-danger rounded-circle d-flex align-items-center justify-content-center pointer text-white" style={{height:"16px", width:"16px", top:"-8px", right:"-8px"}} onClick={()=>{handleDelete(item.upload)}}><i className="bi bi-x"></i></span>
                                    
                                    <span className="text-truncate">{decodeURIComponent(item.upload.split("_")[1])}</span>
                                    <a href={item.upload} target="_blank"><i className="bi bi-cloud-download ms-1"></i></a>
                                </div>
                                ))}
                               
                            </div>
                        <div className="file-upload-wrapper mb-3">
                            <Form.Control type="file" id="attachFile-2" className="d-none" onChange={uploadFiles__} multiple />
                            <label for="attachFile-2" className="attachFile-wrapper d-flex align-items-center justify-content-between gap-2 rounded-3 p-2" title='Upload File'>
                                <div className="d-flex align-items-center gap-1 text-muted pointer"><i className="bi bi-paperclip fs-5"></i> Attach file</div>
                            </label>
                        </div>

                        <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 pt-3">
                            <Button variant="secondary" onClick={close} className='btn-custom px-5 py-2'>Cancel</Button>
                            <Button type='submit' variant="success" className='btn-custom px-5 py-2' >Submit</Button>
                           
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
