import React, { useState } from "react";
import './issues.scss';
import { Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

// Quill Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getShortName } from "../../utils/emailUtils";
import { callAxios, callAxiosFile } from "../../utils/utils";
import { environment } from "../../environment";
import { toast } from "react-toastify";
import { ReopenIssueModal } from "./ReopenIssueModal";
import $ from "jquery";

export const IssueListingCard = ({ items, userRole, status, currentItem, setCurrentItem, user, getIssues, setIsloading,setIssuesCount, startDate, endDate,active, setActive }) => {
  //  console.log(currentItem, items.data)
  // For Sidebar list selected card

  const [files, setFiles] = useState([]);

  const [preview, setPreview] = useState([])

  const [isUploading, setIsuploading] = useState(false)

  const [availableStatus, setAvailableStatus] = useState([])

  const [replyIdClosed, setReplyIdClosed] = useState(0);
  // Add new meeting modal
  const [showReopenIssueModal, setShowReopenIssueModal] = useState(false);
  const closeReopenIssueModal = () => setShowReopenIssueModal(false);
  const openReopenIssueModal = (id) => {
    setShowReopenIssueModal(true);
    setReplyIdClosed(id)
  }

  // const [active, setActive] = useState(0);

  const [reply, setReply] = useState("");

  // Define the quill toolbar modules
  const toolbarModules = {
    toolbar: {
      container: '#toolbar', // Reference the ID of the custom toolbar
      handlers: {
        // Add custom handlers if necessary
      }
    },
    toolbar: [
      // [{ 'font': [] }],
      [{ 'header': '1' }, { 'header': '2' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'list': 'bullet' }, { 'list': 'ordered' }],
      // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      // [{ 'color': [] }, { 'background': [] }],
      ['link',
        // 'video', 'formula'
      ],
      ['blockquote', 'code-block'], // Quote
      ['clean'] // Remove formatting button
    ],
  };

  // Define the quill formats that are allowed
  const formats = [
    'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
  ];
  let htmlContent;
  const formatAMPM = (date) => {
    //console.log("LOCAL TIME:",date)
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const getIssueDetails = async (item) => {
    setCurrentItem(item)
  }
  let replyContent;

  if(currentItem && currentItem?.issues_replies_bdms && currentItem?.issues_replies_bdms.length > 0 ){
    const sortedReplies = currentItem?.issues_replies_bdms.sort((a, b) => b.id - a.id);
    replyContent = sortedReplies.map((item, index)=>{
        return(
            <div className="info-card rounded-3 p-3">
        <div className="d-flex flex-wrap flex-sm-nowrap align-items-sm-center gap-2">
            <div className="user-img bg-warning bg-opacity-75 rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '40px', width: '40px', minWidth: '40px' }}>
                 {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" />  */}
                <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">
                {userRole?.userType === "SA" || userRole?.userType === "SSA" ? (
                        item.userType === "BDM" || item.userType === "CAM" ? (
                          <>
                            {item.name
                              ? "" + getShortName(item.name)
                              : null}
                            
                          </>

                        ) :
                          getShortName("Admin")


                      ) : item.userType === "SA" || item.userType === "SSA" ? (

                       getShortName("Admin")

                      ) : (

                        getShortName("User")

                      )}

                </div>
            </div>
            <div className="user-email-info w-100">
                <div className="row align-items-center g-2">
                    <div className="col-sm-6">
                        <div className="user-name fs-6 fw-medium text-white text-truncate text-capitalize">
                        {userRole?.userType === "SA" || userRole?.userType === "SSA" ? (
                        item.userType === "BDM" || item.userType === "CAM" ? (
                          <>
                            {item.name
                              ? "" + item.name
                              : null}
                            
                          </>

                        ) :
                          "Admin"


                      ) : item.userType === "SA" || item.userType === "SSA" ? (

                      "Admin"

                      ) : (

                        "User"

                      )}
                        </div>
                        {item.name === "Admin"?null:
                        <div className="user-team fs-12 text-white text-opacity-75 text-capitalize">{item.userType}</div>
    }
                    </div>
                    <div className="col-sm-6">
                        <div className="d-flex flex-wrap align-items-center justify-content-sm-end row-gap-1 column-gap-2">
                            <div className="text-white fs-14 fw-medium d-flex flex-wrap">
                            <span className="name text-uppercase">
                                    {new Date(item.createdAt).toLocaleString("default", {
              day:"2-digit",
            }) +
            "/" +
            new Date(item.createdAt).toLocaleString("default", {
              month:"2-digit",
            }) +
            "/" +
            new Date(item.createdAt).getFullYear() }</span>
                                <span className="time text-uppercase ms-1">{formatAMPM(new Date(item.createdAt))}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="user-details mt-4 ms-sm-5">
            <div className="description-wrapper fs-14 text-light text-opacity-75" dangerouslySetInnerHTML={{ __html: item?.reply }} />
                {item.status === "closed"?
                <>
                 <p className="fw-semibold text-danger mb-3">Ticket Closed</p>
                <div className="btn-wrapper mb-2">
                    <Button onClick={()=>{openReopenIssueModal(item.id)}} variant="secondary" className="px-3">Reopen the ticket</Button>
                </div>
                </>

                :null}

        </div>
        {item?.issues_attachments_bdms && item?.issues_attachments_bdms.length>0 ?
        <div className="files-wrapper d-flex flex-wrap gap-2 mb-2">
            { item?.issues_attachments_bdms.map((item)=>(
              <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2">
                  <span className="text-truncate">{decodeURIComponent(item.filename)}</span>
                  <a href={item.url} target="_blank"><i className="bi bi-cloud-download ms-1"></i></a>
              </div>
              ))}
          </div>
          :null}
    </div>
        )
    })
  } 

  // console.log(currentItem)

  if (items && items?.data?.length > 0) {
    // console.log("in greter 0")
    htmlContent = items?.data?.map((item, idx) => {
      // const isSelected = item.status === "" ?"selected" : null;
      const raisedBy = item.name
        //   ? "" + item.name + " [ " + item.userType + " ]"
        ? "" + item.name
        : null;

      return (
        <div className={`issue-card rounded pointer p-3 ${idx === active ? "selected" : ""}`} key={idx} onClick={() => {
          setActive(idx);
          getIssueDetails(item);
        }}>
         
          <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-1">
                                <div className="user-name fs-14 text-white text-opacity-75 text-truncate text-capitalize">
                                {userRole?.userType === "SA" || userRole?.userType === "SSA" ? (  
              item.userType === "BDM" || item.userType === "CAM" ? (
                <span className="text-warning ms-1">{raisedBy}</span>
              ) : (
                <span className="text-warning ms-1">
                  Admin
                </span>
              )
            ) : item.userType === "SA" || item.userType === "SSA" ? (
              <span className="text-warning ms-1">
                Admin
              </span>
            ) : (
              <span className="text-warning ms-1">
                User
              </span>
            )}
                                </div>
                                {(userRole?.userType === "SSA") && (
                                  currentItem && (currentItem.status === "open" ||currentItem.status === "Reopen" || currentItem.status === "In Progress" || currentItem.status === "In Discussion")?
                                <div className="issue-status">
                                <Form.Select value={item.status} className="bg-light fs-12 fw-medium border-0 rounded-1 lh-sm ps-2 pe-4 py-1" style={{ maxWidth: '90px',backgroundPosition: 'right .35rem center' }} required id="changeStatus" onChange={(e) => {
                    updateStatus(e.target.value, item.id, item.status);
                    
                  }}>
                                    <option value="" >Change status</option>
                                    <option value="open">Open</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="In Discussion">In Discussion</option>
                                    <option value="closed">Closed</option>
                                </Form.Select>
                                </div>:null)}
                            </div>
          <div className="fs-6 fw-medium text-truncate text-white mb-1">{item.subject}</div>
          <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
            <div className="text-white fs-14 d-flex flex-wrap gap-1">
              <span className="name text-uppercase">
                {new Date(item.createdAt).getDate() +
                  " " +
                  new Date(item.createdAt).toLocaleString("default", {
                    month: "long",
                  }) +
                  " " +
                  new Date(item.createdAt).getFullYear()}</span>-
              <span className="time text-uppercase">{formatAMPM(new Date(item.createdAt))}</span>
            </div>
            {item?.priority === "high" ?
              <span className="badge text-bg-danger fw-medium border rounded-1" style={{ minWidth: '63px' }}>High</span>
              : null}
            {item?.priority === "medium" ?
              <span className="badge text-bg-warning fw-medium border rounded-1" style={{ minWidth: '63px' }}>Medium</span>
              : null}
            {item?.priority === "normal" ?
              <span className="badge text-bg-warning fw-medium border rounded-1" style={{ minWidth: '63px' }}>Normal</span>
              : null}
              {item?.priority === "low" ?
              <span className="badge text-bg-secondary fw-medium border rounded-1" style={{ minWidth: '63px' }}>Low</span>
              : null}
            {/* <span className="badge text-bg-success fw-medium border rounded-1" style={{ minWidth: '63px' }}>Normal</span> */}
            {/* <span className="badge text-bg-warning fw-medium border rounded-1" style={{ minWidth: '63px' }}>Medium</span> */}
          </div>
        </div>
      )

    });
  } else{
    // console.log("in 0 ")
    htmlContent = (
    <span className="text-warning">No   {status} quries found!</span>)
  }
 
  // save replies

  if (reply) {
    document.getElementById("editorBorder-incentive").style.border = "none";
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log(reply)
    if (reply === "") {
      document.getElementById("editorBorder-incentive").style.border = "1px solid red";
      return false;
    } else {
      setIsloading(true)
      const formData = {
        issue_id: currentItem?.id,
        uid: user?.uid,
        reply: reply,
        name: user?.displayName,
        email: user?.email,
        userType: userRole?.userType,
        files:preview,
        attachmentType:"reply"
      };
      //console.log(formData)

      const response = await callAxios(
        environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/save-reply",
        formData,
        "post"
      );
      if (response?.data?.status === 200) {
        // resetForm();
        setReply("");
        setPreview([])
        toast.success("Reply Submitted!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        
        await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/get-reply-v2",
      { issueId: currentItem?.id },
      "post"
    ).then((replies) => {
      setIsloading(false)
      setCurrentItem(replies.data)
    
    });
      } else {
        document.getElementById("replies-preloader").classList.add("d-none");
        toast.error("Network Issue!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const resetForm = async () => {
    setReply("");
        await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/get-reply-v2",
      { issueId: currentItem?.id },
      "post"
    ).then((replies) => {
      setIsloading(false)
      // console.log(replies.data)
      setCurrentItem(replies.data)
      
      // document.getElementById("ReplyForm").reset();
      // currentItem['issues_replies'] = replies.data
    });



  };


  const updateStatus = async (status, id, currentStatus) => {
    //console.log(ee)
    setIsloading(true)
    const formData = {
      status: status,
      id: id,
      currentStatus:currentStatus
    };
    //console.log(formData)

    const response = await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/update-status",
      formData,
      "post"
    );
    if (response?.data?.status === 200) {
      $(".ql-editor").html("")
      resetForm();
      getIssues(status)
      toast.success("Status updated!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // await callAxios(
      //   environment.REACT_APP_API_URL_Live + "api/v1/issues/get-issue-dashboard",
      //   {},
      //   "get"
      // ).then((counts) => {
        
      //    setIssuesCount(counts.data);
      //   setIsloading(false);
      // });
    } else {
      //document.getElementById("replies-preloader").classList.add('d-none');
      toast.error("Network Issue!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const uploadFiles = async (event) => {
    setIsloading(true)
    const selectedFiles = event.target.files; // Get the selected files
    // console.log(selectedFiles);
  
    // Check if there are any selected files
    if (selectedFiles.length > 0) {
      const formData = new FormData();
  
      // Append each selected file to the formData object
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('files', selectedFiles[i]); // 'files' should match the key in the backend
      }
  
      try {
        // Send a POST request to the Express API with the files
        const response = await callAxiosFile(environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/upload-to-s3", formData);

        setPreview(response.data)
        setIsloading(false)
        // console.log('Files uploaded successfully:', response.data);
  
        // Clear the file input
        event.target.value = null; // Reset the file input
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };

  const handleDelete = async(itemUrl) =>{
    // console.log(itemId, itemUrl)
    const updatedAttachments = preview.filter(item => item.upload !== itemUrl);
    setPreview(updatedAttachments)
    callAxios(environment.REACT_APP_API_URL_Live + "api/v2/bdm-issues/delete-attachment",{url:itemUrl},"post").then((res)=>{
      
    })
  }
  
  return (
    <div className="row g-3 g-lg-2">
      <div className="col-lg-4 col-xxl-3">
        <div className="issues-list-section d-flex flex-column gap-2">
          {htmlContent}
        </div>
      </div>
      <div className="col-lg-8 col-xxl-9">
        <div className="issues-details-section w-100 d-flex flex-column gap-2">
          {/* Editor Section */}
          {(currentItem) && (userRole?.userType === "SA") && (
           
          <div className="editor-section border border-light border-opacity-50 rounded d-flex flex-column flex-fill p-2">
            <form id="ReplyForm">
              <div className="mb-4" id="editorBorder-incentive">
                {/* React Quill Editor */}
                <ReactQuill id="bdmqueryie" className="custom-quill-editor d-flex flex-column flex-fill overflow-auto" theme="snow" placeholder="Please enter reply here.."
                  modules={toolbarModules}
                  formats={formats}
                  onChange={setReply}
                  
                />
              </div>

              <div className="end-section px-2">
                <div className="files-wrapper d-flex flex-wrap gap-2 mb-2">
                                {preview && preview.length >0 && preview.map((item,index)=>(
                                <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 position-relative px-2 py-1 py-xl-2">
                                  <span className="deleteAttachment position-absolute z-3 bg-danger rounded-circle d-flex align-items-center justify-content-center pointer text-white" style={{height:"16px", width:"16px", top:"-8px", right:"-8px"}} onClick={()=>{handleDelete(item.upload)}}><i className="bi bi-x"></i></span>
                                    
                                    <span className="text-truncate">{decodeURIComponent(item.upload.split("_")[1])}</span>
                                    <a href={item.upload} target="_blank"><i className="bi bi-cloud-download ms-1"></i></a>
                                </div>
                                ))}
                               
                            </div>
                <div className="file-upload-wrapper">
                  <Form.Control type="file" id="attachFile" className="d-none" onChange={uploadFiles} multiple/>
                  <label for="attachFile" className="submit-msg-wrapper d-flex align-items-center justify-content-between gap-2 rounded-3 p-2">
                    <div className="d-flex align-items-center gap-1 text-white text-opacity-75 pointer" ><i className="bi bi-paperclip fs-5"></i> Attach file</div>
                    <Button type="submit" variant="primary" size="sm" className="border-light rounded-3 px-4" onClick={handleFormSubmit}>Reply Query</Button>

                   
                  </label>
                </div>
              </div>
            </form>
          </div>
         
          )}



          {replyContent}

          {currentItem && Object.entries(currentItem).length > 0 ?
            <div className="info-card rounded-3 p-3">
              <div className="d-flex flex-wrap align-items-xl-center flex-sm-nowrap gap-2">
                <div className="user-img bg-info bg-opacity-75 rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '40px', width: '40px', minWidth: '40px' }}>

                  <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">
                  {userRole?.userType === "SA" || userRole?.userType === "SSA" ? (
                        currentItem.userType === "BDM" || currentItem.userType === "CAM" ? (
                          <>
                            {currentItem.name
                              ? "" + getShortName(currentItem.name)
                              : null}
                           
                          </>

                        ) :
                          getShortName("Admin")


                      ) : currentItem.userType === "SA" || currentItem.userType === "SSA" ? (

                       getShortName("Admin")

                      ) : (

                        getShortName("User")

                      )}

                  </div>
                </div>
                <div className="user-email-info w-100">
                  <div className="row align-items-center g-2">
                    <div className="col-xl-5">

                      {userRole?.userType === "SA" || userRole?.userType === "SSA" ? (
                        currentItem.userType === "BDM" || currentItem.userType === "CAM" ? (
                          <>
                            <div className="user-name fs-6 fw-medium text-white text-truncate text-capitalize">{currentItem.name
                              ? "" + currentItem.name
                              : null}</div>
                            <div className="user-team fs-12 text-white text-opacity-75 text-capitalize">{currentItem.userType}</div>
                          </>

                        ) :
                          <div className="user-name fs-6 fw-medium text-white text-truncate text-capitalize"> Admin</div>


                      ) : currentItem.userType === "SA" || currentItem.userType === "SSA" ? (

                        <div className="user-name fs-6 fw-medium text-white text-truncate text-capitalize"> Admin</div>

                      ) : (

                        <div className="user-name fs-6 fw-medium text-white text-truncate text-capitalize"> User</div>

                      )}



                    </div>
                    <div className="col-xl-7">
                      <div className="d-flex flex-wrap align-items-center justify-content-xl-end row-gap-1 column-gap-2">
                        {/* <div className="issue_id fs-6 fw-bold text-danger text-capitalize">VEISSUE{currentItem.id}</div> */}
                        <div className="badge text-bg-transparent fw-medium border rounded-1" style={{ minWidth: '63px' }}>Query</div>



                        {currentItem?.priority === "high" ?
                          <div className="badge text-bg-danger fw-medium border rounded-1" style={{ minWidth: '63px' }}>High</div>
                          : null}
                        {currentItem?.priority === "medium" ?
                          <div className="badge text-bg-warning fw-medium border rounded-1" style={{ minWidth: '63px' }}>Medium</div>
                          : null}
                        {currentItem?.priority === "normal" ?
                          <div className="badge text-bg-success fw-medium border rounded-1" style={{ minWidth: '63px' }}>Normal</div>
                          : null}

                        {currentItem?.priority === "low" ?
                                      <div className="badge text-bg-secondary fw-medium border rounded-1" style={{ minWidth: '63px' }}>Low</div>
                                      : null}

                        <div className="text-white fs-14 fw-medium d-flex flex-wrap">
                          <span className="name text-uppercase">
                            {new Date(currentItem.createdAt).toLocaleString("default", {
                              day: "2-digit",
                            }) +
                              "/" +
                              new Date(currentItem.createdAt).toLocaleString("default", {
                                month: "2-digit",
                              }) +
                              "/" +
                              new Date(currentItem.createdAt).getFullYear()}</span>
                          <span className="time text-uppercase ms-1">{formatAMPM(new Date(currentItem.createdAt))}</span>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-details mt-4 ms-sm-5">
                <div className="subject-heading fs-6 fw-semibold text-light mb-3">Sub: {currentItem.subject}</div>
                <div className="description-wrapper fs-14 text-light text-opacity-75 mb-3" dangerouslySetInnerHTML={{ __html: currentItem?.description }}>

                </div>


              </div>
              {currentItem?.issues_attachments_bdms && currentItem?.issues_attachments_bdms.length>0 ?
        <div className="files-wrapper d-flex flex-wrap gap-2 mb-2">
            { currentItem?.issues_attachments_bdms.map((item)=>(
              <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2">
                  <span className="text-truncate">{item.filename.replace("%20"," ")}</span>
                  <a href={item.url} target="_blank"><i className="bi bi-cloud-download ms-1"></i></a>
              </div>
              ))}
          </div>
          :null}
            </div>
            : null}

        </div>
      </div>
      <ReopenIssueModal show={showReopenIssueModal} close={closeReopenIssueModal} currentItem={currentItem} replyIdClosed={replyIdClosed} getIssues={getIssues} setShowReopenIssueModal={setShowReopenIssueModal} setIsloading={setIsloading} setIssuesCount={setIssuesCount} status={status}/>
    </div>
  )
}
